// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-en-js": () => import("./../../src/pages/blog.en.js" /* webpackChunkName: "component---src-pages-blog-en-js" */),
  "component---src-pages-blog-es-js": () => import("./../../src/pages/blog.es.js" /* webpackChunkName: "component---src-pages-blog-es-js" */),
  "component---src-pages-index-en-js": () => import("./../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

